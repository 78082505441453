import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../contexts/alert/AlertContext";
import {
  fetchConversationById,
  submitMessageFeedback,
} from "../pages/RHSCAssistant/apis";
import { updateConversationId, updateLastRHSCPageVisit } from "../utils";
import { useAuthContext } from "../contexts/authentication/AuthenticationContext";
import moment from "moment";
import {
  RHSC_ASSISTANT_API_BASE_URL,
  STREAMING_API_BASE_URL,
} from "../apis/endpoints";

interface IUserMessage {
  message: string;
  isUserMessage: boolean;
}

export interface IBotMessage {
  isUserMessage: boolean;
  messageID: string;
  message: string;
  feedback: {
    option: string;
    comment: string;
  };
  sources: {
    [key: string]: string;
  };
  otherLinks: {
    [key: string]: string;
  };
  shouldStream: boolean;
}

export type ConversationType = IUserMessage | IBotMessage;

export default function useConversation({ id }: { id: string }) {
  const { user } = useAuthContext();
  const alert = useContext(AlertContext);
  const [conversation, setConversation] = useState<ConversationType[]>([]);
  const [conversationID, setConversationID] = useState<string>(id);
  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);
  const [isConversationLoading, setIsConversationLoading] =
    useState<boolean>(false);
  const [isSubmittingFeedback, setIsSubmittingFeedback] =
    useState<boolean>(false);
  const [submittingFeedbackFor, setSubmittingFeedbackFor] =
    useState<string>(null);

  const getParams = () => {
    let params = new URLSearchParams();
    params.append("userEmail", user?.email);
    params.append("conversationID", id);

    return params;
  };

  function generateTimestamp() {
    // Get the current time with moment
    const now = moment();

    // Format the date and time
    let formattedDate = now.format("YYYY-MM-DDTHH:mm:ss.SSS");

    // Get the timezone offset in hours and minutes
    const offset = now.utcOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;
    const formattedOffset =
      (offset >= 0 ? "+" : "-") +
      String(offsetHours).padStart(2, "0") +
      ":" +
      String(offsetMinutes).padStart(2, "0");

    return `${formattedDate}${formattedOffset}`;
  }

  useEffect(() => {
    if (id.length) {
      setIsConversationLoading(true);
      fetchConversationById({
        endpoint: "/conversation",
        params: getParams(),
        successCallback: (data) => {
          const _conversation = data.flatMap(
            ({
              question,
              answer,
              createdAt,
              feedback,
              messageID,
              otherLinks,
              sources,
            }) => [
              { message: question, isUserMessage: true },
              {
                message: answer,
                isUserMessage: false,
                feedback,
                messageID,
                otherLinks,
                sources,
                shouldStream: false,
              },
            ]
          );

          setConversation(_conversation);
          setIsConversationLoading(false);
        },
        failureCallback: ({ response }) => {
          alert.addAlert(
            response.status === 500
              ? "The backend service is down"
              : response?.data || "Something went wrong",
            "danger"
          );
          setIsConversationLoading(false);
        },
      });
    }
  }, [id]);

  const handleChunk = (chunk) => {
    setConversation((prevConversation) => {
      const existingMessageIndex = prevConversation.findIndex(
        (msg) => "messageID" in msg && msg.messageID === chunk.messageID
      );

      if (existingMessageIndex !== -1) {
        // Message exists, update it
        const existingMessage = prevConversation[
          existingMessageIndex
        ] as IBotMessage;
        const updatedMessage: IBotMessage = {
          ...existingMessage,
          message: chunk.response.answer,
          sources: chunk.streamEnded
            ? chunk.response.sources
            : existingMessage.sources,
          shouldStream: true,
        };

        const updatedConversation = [...prevConversation];
        updatedConversation[existingMessageIndex] = updatedMessage;
        return updatedConversation;
      } else {
        // Message does not exist, add it
        const newMessage: IBotMessage = {
          isUserMessage: false,
          messageID: chunk.messageID,
          message: chunk.response.answer,
          feedback: {
            option: "",
            comment: "",
          },
          sources: {},
          otherLinks: {},
          shouldStream: true,
        };

        return [...prevConversation, newMessage];
      }
    });
  };

  const handleStream = async (input: string) => {
    try {
      const response = await fetch(
        `${STREAMING_API_BASE_URL}/rhsc/conversation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/x-ndjson",
            Authorization: `Bearer ${window?.sessionjs?.token}`,
          },
          body: JSON.stringify({
            conversationID: conversationID,
            timestamp: generateTimestamp(),
            userInfo: {
              userEmail: user?.email,
              userName: user?.name,
            },
            question: input,
            newConversation: conversationID?.length === 0,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        // Decode the chunk of data
        const decodedValue = decoder.decode(value, { stream: true });
        buffer += decodedValue;
        // console.log(buffer);

        // Split the buffer on every complete JSON object using regex to safely match entire objects
        const parts = buffer.split(/(?<=})(?={)/g);

        for (let i = 0; i < parts.length; i++) {
          let chunk = parts[i];

          // If it's the last part, it might be incomplete, so retain it in the buffer
          if (i === parts.length - 1 && !chunk.endsWith("}")) {
            buffer = chunk;
          } else {
            try {
              const parsedChunk = JSON.parse(chunk);
              setIsMessageSending(false);
              handleChunk(parsedChunk);

              // Handle conversation ID update
              if (!conversationID) {
                setConversationID(parsedChunk?.conversationID);
                updateConversationId(parsedChunk?.conversationID);
                updateLastRHSCPageVisit(
                  `/rhsc-assistant/conversations/${parsedChunk?.conversationID}`
                );
              }

              // Clear the buffer if we've successfully parsed a complete JSON object
              buffer = "";
            } catch (e) {
              // If parsing fails, keep the remaining chunk in the buffer for the next iteration
              if (i === parts.length - 1) {
                buffer = chunk;
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error during streaming:", error);
      setIsMessageSending(false);
    } finally {
    }
  };

  const handleSendMessage = (input: string) => {
    setIsMessageSending(true);
    const userMessage: IUserMessage = {
      message: input,
      isUserMessage: true,
    };

    setConversation((prevConversation) => [...prevConversation, userMessage]);
    handleStream(input);
  };

  const handleFeedbackIconClick = (
    messageId: string,
    type: string,
    comment: string
  ) => {
    setIsSubmittingFeedback(true);
    setSubmittingFeedbackFor(messageId);

    submitMessageFeedback({
      endpoint: "/feedback",
      dataToSend: {
        conversationID: conversationID,
        messageID: messageId,
        feedback: {
          option: type,
          comment: comment,
        },
      },
      successCallback: (data) => {
        alert.addAlert("Feedback submitted.", "success");
        const updatedConversations = conversation.map((message) => {
          if (!message.isUserMessage && message["messageID"] === messageId) {
            return {
              ...message,
              feedback: {
                option: type,
                comment: comment,
              },
              otherLinks: data.otherLinks,
            };
          }
          return message;
        });

        setIsSubmittingFeedback(false);
        setSubmittingFeedbackFor(null);
        setConversation(updatedConversations);
      },
      failureCallback: ({ response }) => {
        setIsSubmittingFeedback(false);
        setSubmittingFeedbackFor(null);

        alert.addAlert(
          response.status === 500
            ? "The backend service is down"
            : response?.data || "Something went wrong",
          "danger"
        );
      },
    });
  };

  const resetToNewConversation = () => {
    setConversation([]);
    setConversationID("");
    updateConversationId(null);
  };

  return {
    conversation,
    isMessageSending,
    isConversationLoading,
    isSubmittingFeedback,
    submittingFeedbackFor,
    handleSendMessage,
    handleFeedbackIconClick,
    resetToNewConversation,
  };
}
